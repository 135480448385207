function About (props) {
    return (
        <div className="about-page">
            <div className="about-header">
                <h1 className="about-heading">About</h1>
                <div className="about-header-image-container">
                    <img 
                    className="about-header-image"
                    src="https://i.imgur.com/6nWJd8C.png" alt="piano" />
                </div>
            </div>
            <div className="about-content-section">
            <img 
                className="about-section-image4"
                src="https://coursereport-s3-production.global.ssl.fastly.net/uploads/school/logo/2/original/CMYK-Red_Small_GeneralAssembly-Cog__1_.png" alt="General Assembly" />
                <p className="about-section-text"> My software engineering journey began in Highschool with a web dev course on the basics of HTML. I will always thank Mr. Miller for sparking my interest in programming. My career path of becoming a software engineer did not begin until May of 2021 when I began the Software Engineering Immersive with General Assembly. The Bootcamp with GA was an incredibly challenging experience, and it really solidified my passion for development.  </p>
            </div>
            <hr/>
            <div className="about-content-section">
                <p className="about-section-text"> When working indoors it is important to get out as much as possible! One of my favorite things to do is to travel and see new places. I particularly enjoy natural spots like the Cascade Mountains, and the Hoh Rainforest in Wasghinton state. </p>
            <img 
                className="about-section-image2"
                src="https://i.imgur.com/Sf820Cp.jpg" alt="mountain" />
            </div>
            <hr/>
            <div className="about-content-section">
            <img 
                className="about-section-image3"
                src="https://cdn-resources.ableton.com/resources/filer_thumbnails/public/2012/10/11/sae.jpg__483x372_q85_crop_subsampling-2_upscale.jpg" alt="S A E Institute" />
                <p className="about-section-text"> My audio education is a big part of who I am today. In 2015 I studied audio engineering at SAE Institute in New York City. Even though audio is largely a field dealing with hardware, I have found that the lessons I learned about troubleshooting and problem solving can apply just as well to Software. </p>
            </div>
            <hr/>
            <div className="about-content-section">
                <p className="about-section-text"> Lastly, my passion for music is one of the most important factors for my character. I have been studying music and playing musical instruments since 2009, and nothing else has been a better tool for me to develop self discipline and strong communication habits. My musical practices have allowed me to think outside of the box and come to unique solutions to many different problems. I have also had the pleasure to make great friends through music, and I can't wait to delve deeper into the possibilities of combining music and programming!</p>
                <img 
                className="about-section-image"
                src="https://i.imgur.com/qDXw1aQ.jpg" alt="musicians" />
            </div>
            
        </div>
    )
}

export default About;