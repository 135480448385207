function Projects (props) {
    return (
        <div className="projects-page">
            <div className="projects-header">
                <h1 className="projects-heading">Projects</h1>
                <div className="projects-header-image-container">
                    <img 
                    className="projects-header-image"
                    src="https://i.imgur.com/YrIL0hj.png" alt="flask" />
                </div>
            </div>


                <div className="project-section">
                    <div className="project-section-image-container">
                        <img 
                        className="project-section-image"
                        src="https://i.imgur.com/V9O2vfNm.png" alt="plugin" />
                    </div>
                    <div className="project-section-body">
                        <h2 className="project-section-title">Rope Burn Distortion</h2>
                        <div className="project-section-body-links">
                            <a 
                            target="_blank"
                            href="https://rope-burn.herokuapp.com/"><div className="project-section-body-link">Live Site</div></a>
                            <a 
                            target="_blank"
                            href="https://github.com/caleb88confer/rope-burn-distortion"><div className="project-section-body-link"> Github</div></a>
                        </div>
                        <p className="project-section-body-description">
                            Rope Burn is an audio VST and AU plugin created using C++ and the JUCE framework. Checkout the live site to see a video demo and to download the plugin. 
                        </p>
                            
                    </div>
                </div>
                <hr/>
                <div className="project-section">
                    <div className="project-section-image-container">
                        <img 
                        className="project-section-image"
                        src="https://i.imgur.com/fEpiTSRm.png" alt="picture of website" />
                    </div>
                    <div className="project-section-body">
                        <h2 className="project-section-title">Chroma Chords</h2>
                        <div className="project-section-body-links">
                            <a 
                            target="_blank"
                            href="https://chroma-chords.herokuapp.com/canvas/palette"><div className="project-section-body-link">Live Site</div></a>
                            <a 
                            target="_blank"
                            href="https://github.com/caleb88confer/chroma-chords-frontend"><div className="project-section-body-link"> Github</div></a>
                        </div>
                        <p className="project-section-body-description">
                            Chroma Chords is a fun experience where a user can pick from a variety of colors and translate them into a musical chord. You can then record your creation for anyone to view in the gallery.  
                        </p>
                            
                    </div>
                </div>
                    <hr/>
                <div className="project-section">
                    <div className="project-section-image-container">
                        <img 
                        className="project-section-image"
                        src="https://i.imgur.com/858r3AMm.png" alt="picture of website" />
                    </div>
                    <div className="project-section-body">
                        <h2 className="project-section-title">EXP Cards</h2>
                        <div className="project-section-body-links">
                            <a 
                            target="_blank"
                            href="https://exp-cards.herokuapp.com/"><div className="project-section-body-link">Live Site</div></a>
                            <a 
                            target="_blank"
                            href="https://github.com/caleb88confer/ExpCards"><div className="project-section-body-link"> Github</div></a>
                        </div>
                        <p className="project-section-body-description">
                            The idea for EXP Cards was born from my love of trading card games like Magic The Gathering, and a desire to create a unique experiance for which to share moments with friends. When you create a post with EXP Cards, it is formated to look like a trading card with different styles based on when moment took place, where it took place, and how you felt about certain aspects of the experience. In this way, user will be able to compare cards that have things in common. In the future I would like to add a deck building feature to EXP Cards, and I would like to implement a game mode. 
                        </p>
                            
                    </div>
                    
                </div>
                <hr/>
                <div className="project-section">
                    <div className="project-section-image-container">
                        <img 
                        className="project-section-image"
                        src="https://i.imgur.com/gj3OB54.png" alt="picture of website" />
                    </div>
                    <div className="project-section-body">
                        <h2 className="project-section-title">Support Local Philly</h2>
                        <div className="project-section-body-links">
                            <a 
                            target="_blank"
                            href="https://caleb88confer.github.io/Support-Local-Philly/"><div className="project-section-body-link">Live Site</div></a>
                            <a 
                            target="_blank"
                            href="https://github.com/caleb88confer/Support-Local-Philly"><div className="project-section-body-link"> Github</div></a>
                        </div>
                        <p className="project-section-body-description">
                            As a resident of the city of Philadelphia, I really wanted the first project I made to be something practical that could be used to help support businesses in Philadelphia county. I used an API from the city of Philadelphia to retrieve information about business that were registered and listed by the city. I then implemented an alogrithm to filter the data based on a given zip-code. This was the first web project I made for my Software Engineering Immersive with General Assembly. In the future I would like to go back to this project to clean up the UI and possibly use several different API's to get more data. 
                        </p>
                            
                    </div>
                </div>
                

        </div>
    )
}

export default Projects;