function Home (props) {
    return (
        <div className="home">
            <div className="home-header">
                <div className="sky">
                </div>
                <div className="land">
                </div>
                <div className="img-container">
                <img
                className="profile-image" 
                src="https://i.imgur.com/OivicR8m.jpg" 
                title="source: imgur.com" />

                <div className="profile-image-outline">
                
                </div>
                </div>

                <div className="home-header-text">
                <h2>
                        <span className="important">Software Engineer</span> <hr/>

                         Co-founder of <span className="important">Uncommon Kin Cidery</span> <br/> <hr />
                          Audio <span className="important">Programmer</span>/Audio <span className="important">Engineer</span>
                    </h2>
                </div>
                    
                {/* <div className="home-header-info">
                <div className="home-header-sub">
                    <h2>
                        <span className="important">Software Engineer</span> <hr/>

                         Co-founder of <span className="important">Uncommon Kin Cidery</span> <br/> <hr />
                          Audio <span className="important">Programmer</span>/Audio <span className="important">Engineer</span>
                    </h2>
                </div>
            </div> */}
            </div>
               

           

           

            <div className="home-main-content">
            <div className="tech-skills">
                <h2 className="tech-skills-title">Technical Skills</h2>
                <p className="tech-skills-body">
                JavaScript | React | NodeJS | HTML5 | CSS3 | Materialize | PostgreSQL | MongoDB | Mongoose | Express | C++ | JUCE | Git/GitHub | Netlify | Heroku | Postman | Ruby | Rails | bcrypt | jQuery | VS Code | Xcode 
                </p>
        
            </div>
                
            </div>

         
        </div>
    )
}

export default Home;