import {Link} from "react-router-dom";
import { FaGithub } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { MdEmail } from "react-icons/md";


function Header (props) {
    return (
        <header>
            <div className="header-name">
                <div className="header-name-background"> 
                <Link 
                     style={{
                        // marginTop: 15,
                        textDecoration: "none"
                    }}
                to="/"><h1 className="header-link-title">Caleb Confer</h1></Link>
                

                </div>
            </div>
            <div className="header-nav">
                
                <Link 
                   style={{
                    // marginTop: 15,
                    textDecoration: "none"
                }}
                to="/projects"><p className="header-link">Projects</p></Link>
                <Link 
                   style={{
                    // marginTop: 15,
                    textDecoration: "none"
                }}
                to="/about"><p className="header-link">About</p></Link>
                
            </div>
            <div className="header-social">
               
                <a 
                target="_blank"
                className="icon-text"
                href="https://github.com/caleb88confer"
                > 
                 <div className="icon">
                <FaGithub 
                className="icon"
                />
                <p className="icon-text">Github</p> 
                </div>
                </a>
                
                
                <a 
                target="_blank"
                className="icon-text"
                href="https://www.linkedin.com/in/caleb-confer/">
                <div className="icon">
                <FaLinkedin
                className="icon"
                />
                <p className="icon-text"> LinkedIn </p>
                </div>
                </a>

               
                <Link 
                className="icon-text"
                to="/contact">
                <div className="icon">
                <MdEmail 
                className="icon"
                />
                <p className="icon-text"> Contact </p>
                </div>
                </Link>

               
                
                
                 
            </div>
        </header>
    )
}

export default Header;