function Footer (props) {
    return (
        <footer>
            <div className="copyright-container">
            <p className="copyright">© 2021 Caleb Confer </p>
            </div>

        </footer>
    )
}

export default Footer;